// Adapted from https://github.com/reworkcss/css
// because we needed to remove source map support.

/**
 * External dependencies
 */
import inherits from 'inherits';

/**
 * Internal dependencies
 */
import Base from './compiler';

/**
 * Expose compiler.
 */

export default Compiler;

/**
 * Initialize a new `Compiler`.
 */

function Compiler( options ) {
	Base.call( this, options );
}

/**
 * Inherit from `Base.prototype`.
 */

inherits( Compiler, Base );

/**
 * Compile `node`.
 */

Compiler.prototype.compile = function ( node ) {
	return node.stylesheet.rules.map( this.visit, this ).join( '' );
};

/**
 * Visit comment node.
 */

Compiler.prototype.comment = function ( node ) {
	return this.emit( '', node.position );
};

/**
 * Visit import node.
 */

Compiler.prototype.import = function ( node ) {
	return this.emit( '@import ' + node.import + ';', node.position );
};

/**
 * Visit media node.
 */

Compiler.prototype.media = function ( node ) {
	return (
		this.emit( '@media ' + node.media, node.position ) +
		this.emit( '{' ) +
		this.mapVisit( node.rules ) +
		this.emit( '}' )
	);
};

/**
 * Visit container node.
 */

Compiler.prototype.container = function ( node ) {
	return (
		this.emit( '@container ' + node.container, node.position ) +
		this.emit( '{' ) +
		this.mapVisit( node.rules ) +
		this.emit( '}' )
	);
};

/**
 * Visit document node.
 */

Compiler.prototype.document = function ( node ) {
	const doc = '@' + ( node.vendor || '' ) + 'document ' + node.document;

	return (
		this.emit( doc, node.position ) +
		this.emit( '{' ) +
		this.mapVisit( node.rules ) +
		this.emit( '}' )
	);
};

/**
 * Visit charset node.
 */

Compiler.prototype.charset = function ( node ) {
	return this.emit( '@charset ' + node.charset + ';', node.position );
};

/**
 * Visit namespace node.
 */

Compiler.prototype.namespace = function ( node ) {
	return this.emit( '@namespace ' + node.namespace + ';', node.position );
};

/**
 * Visit supports node.
 */

Compiler.prototype.supports = function ( node ) {
	return (
		this.emit( '@supports ' + node.supports, node.position ) +
		this.emit( '{' ) +
		this.mapVisit( node.rules ) +
		this.emit( '}' )
	);
};

/**
 * Visit keyframes node.
 */

Compiler.prototype.keyframes = function ( node ) {
	return (
		this.emit(
			'@' + ( node.vendor || '' ) + 'keyframes ' + node.name,
			node.position
		) +
		this.emit( '{' ) +
		this.mapVisit( node.keyframes ) +
		this.emit( '}' )
	);
};

/**
 * Visit keyframe node.
 */

Compiler.prototype.keyframe = function ( node ) {
	const decls = node.declarations;

	return (
		this.emit( node.values.join( ',' ), node.position ) +
		this.emit( '{' ) +
		this.mapVisit( decls ) +
		this.emit( '}' )
	);
};

/**
 * Visit page node.
 */

Compiler.prototype.page = function ( node ) {
	const sel = node.selectors.length ? node.selectors.join( ', ' ) : '';

	return (
		this.emit( '@page ' + sel, node.position ) +
		this.emit( '{' ) +
		this.mapVisit( node.declarations ) +
		this.emit( '}' )
	);
};

/**
 * Visit font-face node.
 */

Compiler.prototype[ 'font-face' ] = function ( node ) {
	return (
		this.emit( '@font-face', node.position ) +
		this.emit( '{' ) +
		this.mapVisit( node.declarations ) +
		this.emit( '}' )
	);
};

/**
 * Visit host node.
 */

Compiler.prototype.host = function ( node ) {
	return (
		this.emit( '@host', node.position ) +
		this.emit( '{' ) +
		this.mapVisit( node.rules ) +
		this.emit( '}' )
	);
};

/**
 * Visit custom-media node.
 */

Compiler.prototype[ 'custom-media' ] = function ( node ) {
	return this.emit(
		'@custom-media ' + node.name + ' ' + node.media + ';',
		node.position
	);
};

/**
 * Visit rule node.
 */

Compiler.prototype.rule = function ( node ) {
	const decls = node.declarations;
	if ( ! decls.length ) {
		return '';
	}

	return (
		this.emit( node.selectors.join( ',' ), node.position ) +
		this.emit( '{' ) +
		this.mapVisit( decls ) +
		this.emit( '}' )
	);
};

/**
 * Visit declaration node.
 */

Compiler.prototype.declaration = function ( node ) {
	return (
		this.emit( node.property + ':' + node.value, node.position ) +
		this.emit( ';' )
	);
};
